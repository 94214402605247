<template>
  <div class="container">
    <div class="contents">
      <h2 class="login-title">{{ $t('login.main-title') }}</h2>
      <div class="login-wrap">
        <div class="login-info">
          <p v-html="$t('login.sub-title')"></p>
          <span>{{ $t('login.description') }}</span>
        </div>
        <form class="login-form">
          <fieldset>
            <div>
              <input
                type="text"
                v-model="userId"
                class="inp inp-id"
                :placeholder="placeholder.id"
                v-focus
                v-on:keyup.enter="setFocus"
              />
              <input
                type="password"
                v-model="password"
                class="inp inp-pw"
                v-on:keyup.enter="validation"
                :placeholder="placeholder.pw"
              />
              <button type="button" class="btn-login" @click="validation">
                {{ $t('login.main-title') }}
              </button>
            </div>
          </fieldset>
        </form>
      </div>
      <!--login-wrap-->
    </div>
  </div>
  <!--/container-->
</template>
<script>
import { sessionDuplication, deleteLoginStatus } from '@/api/authApi.js';
import sha256 from 'sha256';
export default {
  data() {
    return {
      userId: '',
      password: '',
      placeholder: {
        id: this.$i18n.t('common.placeholder.field-id'),
        pw: this.$i18n.t('common.placeholder.field-password'),
      },
    };
  },
  directives: {
    focus: {
      // 디렉티브 정의
      inserted: function(el) {
        el.focus();
      },
    },
  },
  methods: {
    setFocus(e) {
      e.target.nextSibling.focus();
    },
    // 유효성 검사
    validation(e) {
      if (e.type === 'keyup') {
        e.stopPropagation();
        e.target.blur();
      }
      if (!this.userId || this.userId == '') {
        this.$dialogs.alert(
          this.$i18n.t('login.alert.invalid-required', {
            field: this.$i18n.t('common.placeholder.field-id'),
          }),
        );
        return;
      } else if (!this.password || this.password == '') {
        this.$dialogs.alert(
          this.$i18n.t('login.alert.invalid-required', {
            field: this.$i18n.t('common.placeholder.field-password'),
          }),
        );
        return;
      } else {
        this.sessionDuplication();
      }
    },
    // 아이디 사용 여부 확인
    async sessionDuplication() {
      let userId = this.userId;
      const {
        data: { duplicatedLogin },
      } = await sessionDuplication({ userId });

      if (duplicatedLogin) {
        this.$dialogs.confirm(this.$i18n.t('login.alert.in-use')).then(res => {
          if (res) this.deleteLoginStatus();
        });
      } else {
        this.processLogin();
      }
    },
    // 기존 사용자 접속 종료
    async deleteLoginStatus() {
      let userId = this.userId,
        password = sha256(this.password);
      await deleteLoginStatus({ userId, password });
      this.$dialogs.alert(this.$i18n.t('login.alert.terminated'));
    },
    // 로그인
    async processLogin() {
      let userId = this.userId,
        password = sha256(this.password);

      await this.$store
        .dispatch('auth/LOGIN', { userId, password })
        .then(({ valid }) => {
          if (!valid) {
            this.$dialogs.alert(this.$i18n.t('login.alert.login-fail'));
          } else {
            this.$router.push('/');
          }
        });
    },
  },
};
</script>
<style></style>
